import React, { Component } from "react";
import ContextProvider from "../components/context/contextProvider";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducer from "../reducers";
import "../styles/shared.scss";
import Cookies from "../components/cookies/cookies";
import MessageAlert from "../components/message-alert/message-alert";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/footer";
import { navigate } from "gatsby";
import ResetPage from "../components/reset-page/resetPage";

let store
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStore(allReducer)
}

class Login extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      const session = localStorage.getItem("qvax-session")
      if (session) {
        navigate("/", { state: { lang: this.props.lang } })
      }
    }
  }

  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <Navbar />
          <Cookies />
          <MessageAlert />
          <ResetPage
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
          <Footer />
        </Provider>
      </ContextProvider>
    )
  }
}
export default Login
