import React, { Component } from "react";
import MyContext from "../context/myContext";
import Translation from "../translation/translation";
import { logIn, changeLang } from "../../reducers/actions/index";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import "./reset.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class ResetPage extends Component {
  session
  token
  constructor(props, context) {
    super(props, context)
    this.state = {
      showSignupForm: true,
      client: this.context.sdkClient,
      mailSent: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved)
    }
    if (typeof window !== "undefined") {
      let params = new URL(document.location).searchParams
      this.token = params.get("token")
    }
  }

  render() {
    return (
      <div className="reset_page">
        <div className="titles">
          <h1>
            <Translation trl="reset_pwd" />
          </h1>       
        </div>
        <form>
          <div
            className={
              this.state.pwdError
                ? "form_input error"
                : this.state.pwdError === false
                ? "form_input success"
                : "form_input"
            }
          >
            <label>
              <div>
                <Translation trl="pwd" />
                <br />(
                <span
                  className={this.state.pwdMinLengthError ? "pwd_error" : ""}
                >
                  <Translation trl="pwd_min_length" />
                </span>
                ,{" "}
                <span
                  className={this.state.pwdAlphaNumError ? "pwd_error" : ""}
                >
                  <Translation trl="pwd_alpha" />
                </span>
                ,{" "}
                <span className={this.state.pwdMixCaseError ? "pwd_error" : ""}>
                  <Translation trl="pwd_mix_case" />
                </span>{" "}
                <span
                  className={this.state.pwdSpecialCharError ? "pwd_error" : ""}
                >
                  <Translation trl="pwd_special" />
                </span>
                )
              </div>
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              type={this.state.showPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="**********"
              value={this.state.password}
              onChange={e => this.handlePasswordChange(e.target.value)}
              onBlur={e => this.checkPassword(e.target.value)}
            />
            {!this.state.showPassword && (
              <VisibilityIcon
                className="pwd_icon"
                onClick={() => this.setState({ showPassword: true })}
              />
            )}
            {this.state.showPassword && (
              <VisibilityOffIcon
                className="pwd_icon"
                onClick={() => this.setState({ showPassword: false })}
              />
            )}
          </div>
          <div
            className={
              this.state.confirmPasswordError
                ? "form_input error"
                : this.state.confirmPasswordError === false
                ? "form_input success"
                : "form_input"
            }
          >
            <label>
              <Translation trl="conf_pwd" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              type={this.state.showConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="**********"
              value={this.state.confirmPassword}
              onChange={e => this.setState({ confirmPassword: e.target.value })}
              onBlur={e => this.checkConfirmPassword(e.target.value)}
            />
            {!this.state.showConfirmPassword && (
              <VisibilityIcon
                className="pwd_icon"
                onClick={() => this.setState({ showConfirmPassword: true })}
              />
            )}
            {this.state.showConfirmPassword && (
              <VisibilityOffIcon
                className="pwd_icon"
                onClick={() => this.setState({ showConfirmPassword: false })}
              />
            )}
          </div>
        </form>
        {this.state.errors && (
          <div className="errors">
            {this.state.errors.map((error, index) => (
              <span key={index}>{<Translation trl={error} />}</span>
            ))}
          </div>
        )}
        <div className="actions">
          <button
            id="nextStepRegister"
            className={this.isButtonValid() ? "primary" : "primary disabled"}
            onClick={e => this.onSubmit(e)}
          >
            <span>
              <Translation trl="send" />
            </span>
          </button>
        </div>
      </div>
    )
  }

  hasMinLength = pwd => {
    return pwd.length >= 10
  }

  hasMixCase = pwd => {
    return pwd !== pwd.toLowerCase() && pwd !== pwd.toUpperCase()
  }

  hasAlphaNumeric = pwd => {
    const num = /\d/
    const alpha = /.*[a-zA-Z]+.*/

    return num.test(pwd) && alpha.test(pwd)
  }

  hasSpecialChar = pwd => {
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
    return format.test(pwd)
  }

  handleConfirmPasswordChange(e) {
    const password = e.target.value
    this.setState({
      password2: password,
      pwdConfirmError: password !== this.state.password1,
    })
  }

  handlePasswordChange(password) {
    const pwdMinLengthError = !this.hasMinLength(password)
    const pwdMixCaseError = !this.hasMixCase(password)
    const pwdAlphaNumError = !this.hasAlphaNumeric(password)
    const pwdSpecialCharError = !this.hasSpecialChar(password)
    this.setState({
      password: password,
      pwdMinLengthError,
      pwdMixCaseError,
      pwdAlphaNumError,
      pwdSpecialCharError,
    })
  }

  checkPassword(password) {
    if (password.length > 0) {
      const pwdMinLengthError = !this.hasMinLength(password)
      const pwdMixCaseError = !this.hasMixCase(password)
      const pwdAlphaNumError = !this.hasAlphaNumeric(password)
      const pwdSpecialCharError = !this.hasSpecialChar(password)
      const pwdError =
        pwdMinLengthError ||
        pwdMixCaseError ||
        pwdAlphaNumError ||
        pwdSpecialCharError
      this.setState({
        password: password,
        pwdError,
        pwdMinLengthError,
        pwdMixCaseError,
        pwdAlphaNumError,
        pwdSpecialCharError,
      })
    } else {
      this.setState({ pwdError: undefined })
    }
  }

  checkConfirmPassword(value) {
    if (value.length > 0) {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ confirmPasswordError: true })
      } else {
        this.setState({ confirmPasswordError: false })
      }
    } else {
      this.setState({ confirmPasswordError: undefined })
    }
  }

  isButtonValid() {
    return (
      this.state.password &&
      !this.state.confirmPasswordError &&
      !this.state.pwdError
    )
  }

  onSubmit(event) {
    event.preventDefault()
    this.state.client.sessionService
      .updatePassword({ token: this.token, password: this.state.password })
      .then(res => {
        navigate("/login", { state: { lang: this.props.lang } })
      })
      .catch(e => {
        this.setState({ error: true })
        let error
        if (e.errors && e.errors[0]) {
          try {
            error = JSON.parse(e.errors[0].message)
          } catch (e) {
            console.log(e)
          }
          if (
            error.errors &&
            error.errors.errorsTypes &&
            error.errors.errorsTypes.validation_errors
          ) {
            const errors = error.errors.errorsTypes.validation_errors.map(
              val => val.error.errorCode
            )
            console.log("ERROR =>", errors)
            this.setState({ errors })
          }
        }
      })
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn)
    const dateNow = new Date()
    return expireDate > dateNow
  }

  isClientSide() {
    return typeof window !== "undefined"
  }
}

ResetPage.contextType = MyContext
const mapStateToProps = state => ({
  login: state.login,
  lang: state.lang,
})

const mapDispatchToProps = () => {
  return {
    logIn,
    changeLang,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(ResetPage)
